import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`10:00 EMOM of:`}</p>
    <p>{`1-Snatch Grip Deadlift`}</p>
    <p>{`1-Snatch Grip Deadlift pausing below knee for :03`}</p>
    <p>{`1-Snatch Grip Deadlift + High Pull`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`15-Deadlifts (225/155)`}</p>
    <p>{`45-Double Unders`}</p>
    <p>{`15-Bar Facing Burpees`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      